/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "subjects-migration",
            "region": "us-west-2"
        },
        {
            "tableName": "courses-migration",
            "region": "us-west-2"
        },
        {
            "tableName": "topics-migration",
            "region": "us-west-2"
        },
        {
            "tableName": "subtopics-migration",
            "region": "us-west-2"
        },
        {
            "tableName": "exercises-migration",
            "region": "us-west-2"
        },
        {
            "tableName": "userSubtopics-migration",
            "region": "us-west-2"
        },
        {
            "tableName": "userSettings-migration",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:67cfc50d-0e48-480a-a503-179eaa4566c0",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_0sTEEXiZq",
    "aws_user_pools_web_client_id": "mda1jm2nef8dutnt5beg9417",
    "oauth": {
        "domain": "instructioner-migration.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://instructioner/topics",
        "redirectSignOut": "https://instructioner.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "subjectApi",
            "endpoint": "https://26ky3x29qj.execute-api.us-west-2.amazonaws.com/migration",
            "region": "us-west-2"
        },
        {
            "name": "exerciseApi",
            "endpoint": "https://pe0ls77n2b.execute-api.us-west-2.amazonaws.com/migration",
            "region": "us-west-2"
        },
        {
            "name": "userSubtopicApi",
            "endpoint": "https://qqoutonuv0.execute-api.us-west-2.amazonaws.com/migration",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
