import React from "react";
import {Center, Text} from "@chakra-ui/react";

export const VerificationPage = () => {
    return (
        <Center h={'80vh'}>
            <Text>Verification</Text>
        </Center>
    )
}
